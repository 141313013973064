import React, { useState, useRef, useCallback } from "react"
import { FaRandom, FaCopy, FaCheck, FaEyeSlash } from "react-icons/fa"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import NavBar from "../../components/NavBar/NavBar"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import Popup from "../../componentForPages/Popup/Popup"

import { isSSR } from "../../components/Script/script"

import {
  Title,
  Dropdown,
  Icon,
  Button,
  LoadingAnimation,
  Input,
  Select,
  Control,
  StaticButton,
  Field,
  ArrowDown,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const ScrollEmoticonsList = React.lazy(() =>
  import("./components/emoticonsReactWindowScroll")
)
const DropDownList = React.lazy(() => import("./components/dropDownComponent"))

const seeAlsoArray = [
  "/emoji-copy-and-paste",
  "/font-generator",
  "/html-entity",
  "/text-art",
]

function EmoticonsCopy(props) {
  const { node, allPages } = props.pageContext
  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)
  let eyes = useRef("")
  let mouth = useRef("")
  let ears = useRef("")
  let resultValue = useRef("")
  let [leftEars, setleftEars] = useState(node.ears[0][0])
  let [leftEyes, setleftEyes] = useState(node.eyes[0][0])
  let [mouthValue, setmouthValue] = useState(node.mouth[0])
  let [rightEyes, setrightEyes] = useState(node.eyes[0][0])
  let [rightEars, setrightEars] = useState(node.ears[0][1])
  let [mouthHide, setmouthHide] = useState(true)
  let [eyesHide, seteyesHide] = useState(true)
  let [earsHide, setearsHide] = useState(true)

  function hide() {
    if (mouthHide === true) {
      setmouthHide(false)
      setmouthValue("")
    } else {
      setmouthHide(true)
      setmouthValue(node.mouth[mouth.current.value])
    }
  }
  function ehide() {
    if (eyesHide === true) {
      seteyesHide(false)
      setleftEyes("")
      setrightEyes("")
    } else {
      seteyesHide(true)
      if (node.eyes[eyes.current.value].length === 1) {
        setleftEyes(node.eyes[eyes.current.value][0])
        setrightEyes(node.eyes[eyes.current.value][0])
      } else {
        setleftEyes(node.eyes[eyes.current.value][0])
        setrightEyes(node.eyes[eyes.current.value][1])
      }
    }
  }
  function eahide() {
    if (earsHide === true) {
      setearsHide(false)
      setleftEars("")
      setrightEars("")
    } else {
      setearsHide(true)
      if (node.ears[ears.current.value].length === 1) {
        setleftEars(node.ears[ears.current.value][0])
        setrightEars(node.ears[ears.current.value][0])
      } else {
        setleftEars(node.ears[ears.current.value][0])
        setrightEars(node.ears[ears.current.value][1])
      }
    }
  }
  function mouthFunction() {
    if (mouthHide === true) {
      let list = node.mouth.length
      let randomNumber1 = parseInt(Math.random() * list)
      let result = node.mouth[randomNumber1]
      setmouthValue(result)
      mouth.current.value = mouth.current[randomNumber1].value
    } else {
      setmouthValue("")
    }
  }
  function generator(name, array, setLeft, setRight) {
    let list = array.length
    let randomNumber1 = parseInt(Math.random() * list)
    let result = array[randomNumber1]
    if (result.length === 2) {
      setLeft(result[0])
      setRight(result[1])
    } else if (result.length === 1) {
      setLeft(result)
      setRight(result)
    }
    name.current.value = name.current[randomNumber1].value
  }

  function eyesFunction() {
    generator(eyes, node.eyes, setleftEyes, setrightEyes)
  }
  function earsFunction() {
    generator(ears, node.ears, setleftEars, setrightEars)
  }
  function mouthchange(e) {
    let result = node.mouth[e.target.value]
    setmouthValue(result)
  }
  function change(e, array, setLeft, setRight) {
    let result = array[e.target.value]
    if (result.length === 2) {
      setLeft(result[0])
      setRight(result[1])
    } else if (result.length === 1) {
      setLeft(result)
      setRight(result)
    }
  }
  function eyeschange(e) {
    change(e, node.eyes, setleftEyes, setrightEyes)
  }
  function earschange(e) {
    change(e, node.ears, setleftEars, setrightEars)
  }
  function generateAll() {
    mouthFunction()
    if (eyesHide === true) {
      eyesFunction()
    } else {
      setleftEyes("")
      setrightEyes("")
    }
    if (earsHide === true) {
      earsFunction()
    } else {
      setleftEars("")
      setrightEars("")
    }
  }

  function makecopy() {
    let emoji = resultValue.current.value
    resultValue.current.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
      setTimeout(function () {
        setShow(false)
      }, 1500)
    }
  }

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    setEmoji(emoji)
    setShow(true)
    setTimeout(function () {
      setShow(false)
    }, 2000)
  }, [])

  return (
    <Layout location={props.location}>
      <SEO
        title={`${node.name} ✂️Copy 📋Paste ✌(-‿-)✌`}
        description={
          "Create your very own emoticons faces and text smileys ( ͡° ͜ʖ ͡°). This emoticon text generator helps you get cute fancy emoticon text, Just click on Randomize to make random emoticons and copy paste."
        }
        keywords={[
          `${node.name}, emotes face generator, emotes generator, create your face emoticons, generate emoticons face, generate emotes faces, emoticons generator.`,
        ]}
      />
      <NavBar
        listPages={React.useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: "✂ Emoticons", link: "/emoticons-copy-and-paste/" },
            { name: node.name, link: `/${node.pathUrlLink}/` },
          ]
        }, [node.name, node.pathUrlLink])}
      />
      <section className="container">
        <Title id="listOfEmoji">ψ(｀∇´)ψ {node.name} Copy And Paste</Title>
        {show && (
          <Popup
            emoji={emoji}
            title="Emoticons have been copied"
            close={() => setShow(false)}
          />
        )}
        <br />
        <FlexDiv maxWidth="600px" margin="auto">
          <Input
            fontSize="3rem"
            fontSizeMobile="2rem"
            border="0"
            style={{ textAlign: "center" }}
            type="text"
            aria-label="resultFace"
            value={leftEars + leftEyes + mouthValue + rightEyes + rightEars}
            ref={resultValue}
            readOnly
          />

          <Field>
            <Control>
              <StaticButton>Mouth</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select borderRadius="0">
                <select
                  aria-label="Mouth"
                  ref={mouth}
                  onChange={mouthchange}
                  onBlur={mouthchange}
                  disabled={mouthHide ? "" : true}
                >
                  {node.mouth.map((e, index) => (
                    <option key={`mouth-${index}`} value={index}>
                      {e}
                    </option>
                  ))}
                </select>
              </Select>
            </Control>
            <Control>
              <Button
                margin="0"
                borderRadius="0"
                onClick={mouthFunction}
                disabled={mouthHide ? "" : true}
              >
                <FaRandom />
              </Button>
            </Control>
            <Control>
              <Button margin="0" className="staticButton" onClick={hide}>
                {mouthHide ? <FaCheck /> : <FaEyeSlash />}
              </Button>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Eyes</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select borderRadius="0">
                <select
                  aria-label="Eyes"
                  ref={eyes}
                  onChange={eyeschange}
                  onBlur={eyeschange}
                  disabled={eyesHide ? "" : true}
                >
                  {node.eyes.map((e, index) => {
                    return (
                      <option key={`mouth-${index}`} value={index}>
                        {e}
                      </option>
                    )
                  })}
                </select>
              </Select>
            </Control>
            <Control>
              <Button
                margin="0"
                borderRadius="0"
                onClick={eyesFunction}
                disabled={eyesHide ? "" : true}
              >
                <FaRandom />
              </Button>
            </Control>
            <Control>
              <Button margin="0" className="staticButton" onClick={ehide}>
                {eyesHide ? <FaCheck /> : <FaEyeSlash />}
              </Button>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Ears</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select borderRadius="0">
                <select
                  aria-label="Ears"
                  ref={ears}
                  onChange={earschange}
                  onBlur={earschange}
                  disabled={earsHide ? "" : true}
                >
                  {node.ears.map((e, index) => {
                    return (
                      <option key={`mouth-${index}`} value={index}>
                        {e}
                      </option>
                    )
                  })}
                </select>
              </Select>
            </Control>
            <Control>
              <Button
                margin="0"
                borderRadius="0"
                onClick={earsFunction}
                disabled={earsHide ? "" : true}
              >
                <FaRandom />
              </Button>
            </Control>
            <Control>
              <Button margin="0" className="staticButton" onClick={eahide}>
                {earsHide ? <FaCheck /> : <FaEyeSlash />}
              </Button>
            </Control>
          </Field>

          <Button
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={makecopy}
          >
            <FaCopy className="mr-1" /> Copy
          </Button>
          <Button
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={generateAll}
          >
            <FaRandom className="mr-1" /> Randomize
          </Button>
        </FlexDiv>
        <br />
        <h2>Emoticons Generator</h2>
        <p>
          Create your own emoticons faces and text smileys. Use them wherever
          you'd like.
        </p>
        <p>
          We help you to find cute fancy emoticon text, just click on an
          emoticon to copy it to the clipboard and paste them wherever you want,
          such as on Twitter, Facebook, Instagram etcetera.
        </p>
        <div>
          <br />
          <Dropdown className="mb-4">
            <Button aria-haspopup="true" aria-controls="dropdown-menu">
              Emoticons Category
              <Icon>
                <ArrowDown />
              </Icon>
            </Button>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <DropDownList list={allPages} />
              </React.Suspense>
            )}
          </Dropdown>
        </div>
        <br />
        {!isSSR && (
          <React.Suspense fallback={<LoadingAnimation />}>
            <ScrollEmoticonsList pageItems={node.randomList} mycopy={mycopy} />
          </React.Suspense>
        )}
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmoticonsCopy
